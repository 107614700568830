html {
  box-sizing: border-box;
}

html.loading {
  overflow: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
  font-size: 14px;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
